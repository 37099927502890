import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function KarmaProgramList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "name", sortable: false, label: "Name" },
        { key: "start_date", sortable: false, label: "Start Date" },
        { key: "end_date", sortable: false, label: "End Date" },
        { key: "status", sortable: false, label: "status" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalKarmaProgramList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const karmaProgramsMetaData = ref([]);
    const karmaProgramStatusOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalKarmaProgramList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
        ],
        () => {
            refetchData();
        }
    );

    const fetchKarmaProgramList = (ctx, callback) => {
        store
            .dispatch("app-karma-programs/fetchKarmaPrograms", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
            })
            .then(response => {
                const karma_program = response.data.data;
                const { total } = response.data.meta;
                karmaProgramStatusOption.value = response.data.status;

                callback(karma_program);
                totalKarmaProgramList.value = total;
                karmaProgramsMetaData.value = karma_program;
            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching karma-programs",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == 'finished') return "primary";
        if (status == 'open') return "danger";
        if (status == 'not_started') return "warning";
        if (status == 'started') return "success";
        return "primary";
    };

    return {
        fetchKarmaProgramList,
        tableColumns,
        perPage,
        currentPage,
        totalKarmaProgramList,
        karmaProgramsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        karmaProgramStatusOption,
        statusFilter
    };
}
